import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {GraficasService} from '../../../services/graficas.service';
import {environment} from '../../../../environments/environment';
import {ProyeccionesMercadoService} from '../../../services/proyecciones-mercado.service';
import AES from 'crypto-js/aes';
import CryptoJS from 'crypto-js';
import {AuthService} from '../../../services/auth.service';
import {Subject} from "rxjs";
import { ViewportScroller } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import {InicioSesionComponent} from '../../inicio-sesion/inicio-sesion.component';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {VersionesWindowComponent} from '../../windows/versiones-window/versiones-window.component';
import { TranslateService } from '@ngx-translate/core';
import {SecureStorageService} from '../../../services/secure-storage.service';



@Component({
  selector: 'app-componente-informe',
  templateUrl: './componente-informe.component.html',
  styleUrls: ['./componente-informe.component.scss'],
  providers: []
})
export class ComponenteInformeComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input()
  escenario;
  @Input() historico;

  // Parametros para buscador
  response: any;
  fragment: string;
  trigerContenido: boolean = false;
  fullView = false;
  urlPDF: string;
  closeResult = '';
  downloadButton = false;
  downloadButtonSubject = new Subject<boolean>();
  dataUser: any;
  nameEmpresa = '';
  id_informe = ';'
  constructor(
    private graficasService: GraficasService, private activeRouter: ActivatedRoute, private router: Router,
    private proyeccionService: ProyeccionesMercadoService,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private viewportScroller: ViewportScroller,
    private _snackBar: MatSnackBar,
    private modalService: NgbModal,
    private dialog: MatDialog,
    private translateService: TranslateService,
    private secureStorageService: SecureStorageService
  ) {

    this.activatedRoute.queryParams.subscribe(params => {

       this.id_informe = params.nombre;
      });


    const queryParams = this.activatedRoute.snapshot.queryParams;
    const fullViewParam = decodeURIComponent(queryParams.fullView);

    if (fullViewParam) {
      this.fullView = this.now === this.decrypted(fullViewParam);
    }

    if (this.id_informe) {
      if (this.id_informe == 'Informe escenario pesimista') {
        this.escenario = 'pesimista';
        this.validateProyeccion(this.id_informe);
      }
      if (this.id_informe == 'Informe escenario moderado') {
        this.escenario = 'moderado';
        this.validateProyeccion(this.id_informe);
      }
      if (this.id_informe == 'Informe escenario optimista') {
        this.escenario = 'optimista';
        this.validateProyeccion(this.id_informe);
      }
    } else {
      this.router.navigate(['/inicio/proyecciones-de-mercado']);
    }
  }

  revisar(fullview,anchorID){
    if(fullview){
      this.viewportScroller.scrollToAnchor(anchorID);
    }else{
      let informe = this.escenario;
      if(informe == 'pesimista'){
        informe = 'Informe escenario pesimista';
      }
      if (informe == 'moderado'){
        informe = 'Informe escenario moderado';
      }
      if(informe == 'optimista'){
        informe = 'Informe escenario optimista';
      }

     this.validateInforme(informe);
    }
  }

  validateProyeccion(proyeccion) {


    let user: any = this.secureStorageService.getItem('djangoUser');
    let accessToken = this.secureStorageService.getItem('accessToken');
    let refreshToken = this.secureStorageService.getItem('refreshToken');
    const report = proyeccion.replaceAll(' ', '-');

    if (this.fullView) {
      return;
    }
    if (user && accessToken && refreshToken) { //validacion de login

      this.proyeccionService.consultaContenido(user.email).subscribe(contenido => { // valicacion de contenido asignado

        if (!contenido) { // si no tiene contenido
          this.validateSuscripciones(user, proyeccion);
        } else {
          this.downloadButtonSubject.next(true);
          contenido.forEach(element => {
            if (element.contenido__nombre.indexOf(report) > -1) {
              this.trigerContenido = true;
              this.fullView = true;
              let date = new Date();
              let fecha_inicio = new Date(element.inicio_vigencia);
              let fecha_fin = new Date(element.fin_vigencia);
              if (!(date >= fecha_inicio) && !(date <= fecha_fin)) {
                this.router.navigate(['/inicio/proyecciones-de-mercado']);
              }
            }
          });
          if (this.trigerContenido == false) {
            this.validateSuscripciones(user, proyeccion);
          }
        }
      });


    } else {
      this.router.navigate(['/inicio/proyecciones-de-mercado']);
    }

  }

  validateInforme(proyeccion) {
    // //debugger

    let user: any = this.secureStorageService.getItem('djangoUser');
    let accessToken = this.secureStorageService.getItem('accessToken');
    let refreshToken = this.secureStorageService.getItem('refreshToken');
    const report = proyeccion.replaceAll(' ', '-');
    let trigerContenido = false;

    if (user && accessToken && refreshToken) { //validacion de login

      this.proyeccionService.consultaContenido(user.email).subscribe(contenido => { // valicacion de contenido

        if (!contenido) { // si no tiene registro en contenido valida suscripcion
          this.validateSuscripcionesWEB(user, proyeccion);
        } else {
          contenido.forEach(element => {
            if (element.contenido__nombre.indexOf(report) > -1) {
              trigerContenido = true;
              let date = new Date();
              let fecha_inicio = new Date(element.inicio_vigencia);
              let fecha_fin = new Date(element.fin_vigencia);
              if ((date >= fecha_inicio) && (date <= fecha_fin)) {
                let baseUrl = window.location.href.replace(this.router.url, '');
                // //debugger
                this.router.navigate([]).then(result => {
                  window.open(baseUrl + '/componente-informe/?nombre=' + proyeccion, '_blank');
                });
              } else {
                this.validateSuscripcionesWEB(user, proyeccion);
              }
            }
          });
          if (trigerContenido == false) {
            this.validateSuscripcionesWEB(user, proyeccion, contenido);
          }
        }
      });
    }else {
      const modalRef = this.modalService.open(InicioSesionComponent, {centered: true});
      modalRef.componentInstance.tituloInforme = this.translateService.instant('proyeccionesPersonalizado.tituloInforme');
      // this.modalService.open(InicioSesionComponent, {centered: true}).result.then((result) => {
      //   this.closeResult = `Closed with: ${result}`;
      // }, (reason) => {
      //   this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      // });
    }
  }

  ngOnInit(): void {

    //Obtener credenciales del usuario
    this.dataUser = this.secureStorageService.getItem('djangoUser');
    //Obtener nombre empresa
    this.dataUserLogged();

    this.graficasService.datosInforme({escenario: this.escenario})
      .subscribe((respuesta: any) => {

        this.response = respuesta[0];
        localStorage.setItem('datainforme', JSON.stringify(this.response));
      });

    this.validateDownloadButton();
  }

  validateDownloadButton(): void {
    this.downloadButtonSubject.subscribe(response => {
      if (response) {
        this.downloadButton = response;
        if (this.downloadButton) {
          this.downloadPDF();
        }
      }
    });
  }

  ngAfterViewInit(): void {
    if (this.fragment) {
      this.scrollToAnchor(this.fragment, 1500);
    }
  }

  private async dataUserLogged() {
    if(this.dataUser){
      let promise = this.proyeccionService.getNameBusiness({nit: this.dataUser.empresa}).toPromise();
      await promise.then((data) => {
          this.nameEmpresa = data
        });
    }else{
      return;
    }
  }

  ngOnDestroy(): void {
  }

  /**
   * Scroll to anchor
   *
   * @param {string} location Element id
   * @param {string} wait     Wait time in milliseconds
   */

  scrollToAnchor(location: string, wait: number): void {
    const element = document.querySelector('#' + location);
    if (element) {
      setTimeout(() => {
        element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
      }, wait);
    }
  }

  get urlS3() {
    return environment.urlS3;
  }

  downloadPDF(): void {
    const escenario = this.escenario;
    this.graficasService.downloadPDF({escenario}).subscribe(
      response => {
        
        this.urlPDF = response.url;
      });
  }

  descargarPDF(){
    if(!this.urlPDF){
      this._snackBar.open("Ha ocurrido un error al generar el documento", "Ok");
    }
  }
	
 descargarExcel(){
    const urlExcel  = `https://api.phc.com.co/api/excel?escenario=${this.escenario}`
    window.open(urlExcel, '_blank');
   
  }


  validateSuscripciones(user, proyeccion, contenido?) {
    this.proyeccionService.consultaSuscripcion(user.email).subscribe((data: any) => {
      if (!data) {
        this.router.navigate(['/inicio/proyecciones-de-mercado']);
      } else {
        data.forEach(element => {
          let date = new Date();
          let fecha_inicio = new Date(element.created_at);
          let fecha_fin = new Date(element.created_at);
          fecha_fin.setDate(fecha_fin.getDate() + (element.suscripcion__meses_vigencia * 30));

          if ((date >= fecha_inicio) && (date <= fecha_fin)) {
            this.fullView = true;
            this.downloadButtonSubject.next(true);
          } else {
            this.router.navigate(['/inicio/proyecciones-de-mercado']);
          }
        });
      }
    });
  }

  validateSuscripcionesWEB(user, proyeccion, contenido?) {
    this.proyeccionService.consultaSuscripcion(user.email).subscribe((data: any) => {

      if (!data) {
        this.openWindow();
      } else {
        data.forEach(element => {
          let date = new Date();
          let fecha_inicio = new Date(element.created_at);
          let fecha_fin = new Date(element.created_at);
          fecha_fin.setDate(fecha_fin.getDate() + (element.suscripcion__meses_vigencia * 30));

          if ((date >= fecha_inicio) && (date <= fecha_fin)) {
            // if (!contenido) {
            //   this.openWindow();
            // } else {
              let baseUrl = window.location.href.replace(this.router.url, '');

              this.router.navigate([]).then(result => {
                window.open(baseUrl + '/componente-informe/?nombre=' + proyeccion, '_blank');
              });
            // }
          } else {
            this.openWindow();
          }
        });
      }
    });
  }

  openWindow() {
    const that = this;
    const config = new MatDialogConfig();

    config.disableClose = true;
    config.autoFocus = true;
    config.data = {
      list_teams: [],
      teams: [],
    };
    const dialogRef = this.dialog.open(VersionesWindowComponent, {
      panelClass: 'custom-dialog-container',
      width: '100%',
      maxWidth: '90vw',
      height: '90%'
    });
  }

  decrypted(hash: string): string {
    const decrypted = AES.decrypt(hash, 'abcd@$--12');
    return decrypted.toString(CryptoJS.enc.Utf8);
  }

  get now(): string {
    return new Date().toISOString().slice(0, 10);
  }

  get isLoggedIn(): boolean {
    return this.authService.isLoggedIn();
  }

}
